<template>
  <div id="login-container">
    <div class="login-card">
      <div class="form-container">
        <div class="header">ورود</div>
        <img class="liner" src="../../assets/images/Line-login.svg" alt="" />
        <form class="login-form" @submit.prevent>
          <label class="input-label" for="">
            <input
              v-model="mobile"
              type="text"
              name=""
              id=""
              placeholder="کد پنج رقمی"
            />
            <span class="timer">۱:۳۰</span>
          </label>
          <div @click="tryCode" class="try-code">درخواست مجدد کد</div>
          <button @click="submit" class="btn-singup">ورود</button>
          <button class="btn-register" @click="register">ثبت نام</button>
          <div class="text-information">
            در صورتی که حساب کاربری ندارید ثبت نام کنید
          </div>
        </form>
      </div>
      <img
        class="login-image"
        src="../../assets/images/auth-image.svg"
        height="502"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobile: ""
    };
  },
  methods: {
    submit() {
      this.$router.push("/");
    },
    register() {
      this.$router.push("/register");
    },
    tryCode() {
      //
    }
  }
};
</script>
<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(248, 241, 241);
  opacity: 1;
}
#login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  /*margin-top: 20px;*/
}
.login-card {
  background: #1f3c88;
  border-radius: 20px;
  width: 1052px;
  height: 545px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.login-image {
  position: absolute;
  left: -14%;
  top: -18%;
  width: 70%;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 6%;
  width: 88%;
  max-width: 400px;
  margin-bottom: 80px;
}
.header {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 25px;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.input-label {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: calc(100% - 50px);
  height: 30px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-label input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  width: calc(100% - 35px);
  padding-right: 10px;
  color: #ffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.input-label img {
  height: 24px;
  padding-right: 10px;
}

.liner {
  margin-bottom: 6px;
}

.input-label-text {
  margin: 0;
  padding: 0;
  position: relative;
  top: -62px;
  right: -93px;
  opacity: 75%;
  color: #fff;
}
.try-code {
  position: relative;
  margin-top: -15px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #fff;
  opacity: 0.6;
  text-align: right;
  cursor: pointer;
  align-self: flex-end;
  margin-left: 25px;
}
.btn-singup {
  font-family: "Vazir";
  border: none;
  background: #ffffff;
  border-radius: 10px;
  width: 285px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #070d59;
  cursor: pointer;
}
.btn-register {
  font-family: "Vazir";
  margin-top: 5px;
  margin-bottom: 5px;
  border: none;
  width: 285px;
  height: 40px;
  background: #1f3c88;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  cursor: pointer;
}
.text-information {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #fff;
  position: relative;
  right: -34px;
}
.timer {
  padding-left: 10px;
  color: #ffffff;
  opacity: 0.6;
}
/* responseiv section */
@media only screen and (max-width: 320px) {
  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }
  .login-card {
    width: 100%;
    margin: auto;
  }
  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }
  .login-image {
    display: none;
  }
  .header {
    font-size: 1.5em;
  }
  .liner {
    width: 100%;
  }
  .input-label {
    width: 80%;
  }
  .input-label-error {
    width: 80%;
  }
  .input-label input {
    font-size: 0.7em;
  }
  .input-label-error input {
    font-size: 0.7em;
  }
  .input-label img {
    width: 30px;
  }
  .btn-singup {
    width: 70%;
    font-size: 16px;
  }
  .btn-register {
    width: 70%;
    font-size: 16px;
  }
  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }
  .error-massage {
    font-size: 0.5em;
  }
  .forget-password {
    font-size: 0.5em;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  #login-container {
  }
  .form-container {
    margin: 0 auto;
  }
  .login-card {
    width: 100%;
  }
  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }
  .header {
    font-size: 1.5em;
  }
  .input-label {
  }
  .input-label input {
    font-size: 0.7em;
  }
  .input-label-error {
  }
  .input-label-error input {
    font-size: 0.7em;
  }
  .input-label img {
    width: 30px;
  }
  .error-massage {
    font-size: 0.5em;
  }
  .forget-password {
    font-size: 0.5em;
  }
  .liner {
    width: 100%;
  }
  .btn-singup {
    width: 80%;
    font-size: 1em;
  }
  .btn-register {
    width: 80%;
    font-size: 1em;
  }
}

@media only screen and (max-width: 1140px) {
  .login-image {
    left: -13%;
    top: -13%;
    width: 65%;
  }

  #login-container {
    padding: 0 70px;
  }
}
@media only screen and (max-width: 960px) {
  .login-image {
    left: -11%;
    width: 60%;
    top: -8%;
  }

  #login-container {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }

  .login-card {
    margin-top: 70px;
  }
}
</style>
